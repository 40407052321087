import React, {  } from "react";

import Trainers from "./Trainers";
import Community from "../../components/Community";

const Class02 = () => {
    

    return (
        <>
            <Trainers />
            <Community />
        </>
    );
};

export default Class02;
