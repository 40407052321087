const courses = [
  {
    title: "Power Yoga Sculpt",
    url: "/class01-details",
    image: "/images/content/card-pic-1.png",
    image2x: "/images/content/card-pic-1@2x.png",
    category: "white",
    categoryText: "yoga",
    level: "green",
    levelText: "beginner",
    counterVideo: "7",
    counterUser: "160",
  },
  {
    title: "Power Yoga Sculpt",
    url: "/class01-details",
    image: "/images/content/card-pic-2.png",
    image2x: "/images/content/card-pic-2@2x.png",
    category: "white",
    categoryText: "yoga",
    level: "green",
    levelText: "beginner",
    counterVideo: "7",
    counterUser: "160",
  },
  {
    title: "Power Yoga Sculpt",
    url: "/class01-details",
    image: "/images/content/card-pic-3.png",
    image2x: "/images/content/card-pic-3@2x.png",
    category: "white",
    categoryText: "yoga",
    level: "green",
    levelText: "beginner",
    counterVideo: "7",
    counterUser: "160",
  },
  {
    title: "Power Yoga Sculpt",
    url: "/class01-details",
    image: "/images/content/card-pic-4.png",
    image2x: "/images/content/card-pic-4@2x.png",
    category: "white",
    categoryText: "yoga",
    level: "green",
    levelText: "beginner",
    counterVideo: "7",
    counterUser: "160",
  },
];

const socials = [
  {
    type: "facebook",
    url: "https://facebook.com",
  },
  {
    type: "instagram",
    url: "https://instagram.com",
  },
  {
    type: "twitter",
    url: "https://twitter.com",
  },
  {
    type: "linkedin",
    url: "https://linkedin.com",
  },
];

export const trainersList = [
  {
    title: "Yoga",
    courses: [
      {
        name: "Baron Toy",
        image: "/images/content/avatar-1.png",
        position: "Yoga Master",
        courses: courses,
        socials: socials,
      },
      {
        name: "Kacie Kiehn",
        image: "/images/content/avatar-2.png",
        position: "Yoga Master",
        courses: courses,
        socials: socials,
      },
      {
        name: "Marcelino Hettinger",
        image: "/images/content/avatar-3.png",
        position: "Yoga Master",
        courses: courses,
        socials: socials,
      },
      {
        name: "Rosemarie Hintz",
        image: "/images/content/avatar-4.png",
        position: "Yoga Master",
        courses: courses,
        socials: socials,
      },
      {
        name: "Syble Prohaska",
        image: "/images/content/avatar-5.png",
        position: "Yoga Master",
        courses: courses,
        socials: socials,
      },
      {
        name: "Ila Reinger",
        image: "/images/content/avatar-6.png",
        position: "Yoga Master",
        courses: courses,
        socials: socials,
      },
    ],
  },
  {
    title: "Gym & Fitness",
    courses: [
      {
        name: "Marcelino Hettinger",
        image: "/images/content/avatar-3.png",
        position: "Yoga Master",
        courses: courses,
        socials: socials,
      },
      {
        name: "Rosemarie Hintz",
        image: "/images/content/avatar-4.png",
        position: "Yoga Master",
        courses: courses,
        socials: socials,
      },
      {
        name: "Syble Prohaska",
        image: "/images/content/avatar-5.png",
        position: "Yoga Master",
        courses: courses,
        socials: socials,
      },
      {
        name: "Ila Reinger",
        image: "/images/content/avatar-6.png",
        position: "Yoga Master",
        courses: courses,
        socials: socials,
      },
      {
        name: "Baron Toy",
        image: "/images/content/avatar-1.png",
        position: "Yoga Master",
        courses: courses,
        socials: socials,
      },
      {
        name: "Kacie Kiehn",
        image: "/images/content/avatar-2.png",
        position: "Yoga Master",
        courses: courses,
        socials: socials,
      },
      {
        name: "Baron Toy",
        image: "/images/content/avatar-1.png",
        position: "Yoga Master",
        courses: courses,
        socials: socials,
      },
      {
        name: "Kacie Kiehn",
        image: "/images/content/avatar-2.png",
        position: "Yoga Master",
        courses: courses,
        socials: socials,
      },
    ],
  },
  {
    title: "Gymnastics",
    courses: [
      {
        name: "Baron Toy",
        image: "/images/content/avatar-1.png",
        position: "Yoga Master",
        courses: courses,
        socials: socials,
      },
      {
        name: "Kacie Kiehn",
        image: "/images/content/avatar-2.png",
        position: "Yoga Master",
        courses: courses,
        socials: socials,
      },
      {
        name: "Marcelino Hettinger",
        image: "/images/content/avatar-3.png",
        position: "Yoga Master",
        courses: courses,
        socials: socials,
      },
      {
        name: "Rosemarie Hintz",
        image: "/images/content/avatar-4.png",
        position: "Yoga Master",
        courses: courses,
        socials: socials,
      },
      {
        name: "Syble Prohaska",
        image: "/images/content/avatar-5.png",
        position: "Yoga Master",
        courses: courses,
        socials: socials,
      },
      {
        name: "Ila Reinger",
        image: "/images/content/avatar-6.png",
        position: "Yoga Master",
        courses: courses,
        socials: socials,
      },
      {
        name: "Baron Toy",
        image: "/images/content/avatar-1.png",
        position: "Yoga Master",
        courses: courses,
        socials: socials,
      },
    ],
  },

];
