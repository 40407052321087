import React from "react";

import Comment from "./Comment";



const Pricing = () => {
    return (
        <>
            
            <Comment />
           
           
        </>
    );
};

export default Pricing;
