import React from "react";

import Testimonials from "../../components/Testimonials";

const Class01 = () => {
    return (
        <>
            
            
            <Testimonials className="section-pb" />
        </>
    );
};

export default Class01;
