import React, { useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import cn from "classnames";
import styles from "./Header.module.sass";
import DropdownMenu from "./DropdownMenu";
import Icon from "../Icon";
import Image from "../Image";

const navLinks = [


    {
        title: "Hizmetlerimiz",
        url: "/download",
    },
    {
        title: "Portfolyo",
        url: "/lifestyle",
    },
    {
        title: "Hakkımızda",
        url: "/features",
    },
    {
        title: "İletişim",
        url: "/pricing",
    },

];

const socials = [
    {
        title: "facebook",
        size: "16",
        url: "https://www.facebook.com/ui8.net/",
    },
    {
        title: "twitter",
        size: "18",
        url: "https://twitter.com/ui8",
    },
    {
        title: "instagram",
        size: "16",
        url: "https://www.instagram.com/bikodlayazilim/",
    },
];

const contact = [
    {
        title: "Montanachester",
        content: "06787 Block Estates",
    },
    {
        title: "Lake Gene",
        content: "167 Emard River",
    },
    {
        title: "North Hassiefort",
        content: "032 Leonora Spurs",
    },
];

const Headers = () => {
    const [visibleNav, setVisibleNav] = useState(false);

    const { pathname } = useLocation();

    return (
        <header className={styles.header}>
            <div className={cn("container", styles.container)}>
                <Link
                    className={styles.logo}
                    to="/"
                    onClick={() => setVisibleNav(false)}
                >
                    <Image
                        className={styles.pic}
                        src="/images/logo-dark.png"
                        srcDark="/images/logo-light.png"
                        alt=""
                    />
                </Link>
                <div
                    className={cn(styles.wrap, { [styles.active]: visibleNav })}
                >
                    <nav className={styles.nav}>
                        {navLinks.map((x, index) =>
                            x.content ? (
                                <DropdownMenu
                                    className={styles.group}
                                    item={x}
                                    key={index}
                                    setValue={setVisibleNav}
                                />
                            ) : (
                                <NavLink
                                    className={cn(styles.link, {
                                        [styles.active]: pathname === x.url,
                                    })}
                                    to={x.url}
                                    key={index}
                                    onClick={() => setVisibleNav(false)}
                                >
                                    {x.title}
                                </NavLink>
                            )
                        )}
                    </nav>
                    <div className={styles.details}>
                        <div className={styles.contact}>
                            {contact.map((x, index) => (
                                <div className={styles.element} key={index}>
                                    <div className={styles.category}>
                                        {x.title}
                                    </div>
                                    <div className={styles.text}>
                                        {x.content}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className={styles.socials}>
                            {socials.map((x, index) => (
                                <a
                                    className={styles.social}
                                    href={x.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    key={index}
                                >
                                    <Icon name={x.title} size={x.size} />
                                </a>
                            ))}
                        </div>
    
                    </div>
                </div>
                
                <button
                    className={cn(styles.burger, {
                        [styles.active]: visibleNav,
                    })}
                    onClick={() => setVisibleNav(!visibleNav)}
                ></button>
            </div>
        </header>
    );
};

export default Headers;
