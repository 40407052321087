import React, { Fragment, useRef, useState } from "react";

const Consultation = (props) => {
  const form = useRef();
  const [isSent, setIsSent] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    setIsSent(true);

    // 3 saniye sonra anasayfaya yönlendirme
    setTimeout(() => {
      setIsSent(false);
      // Anasayfaya yönlendirmeyi burada gerçekleştiriyoruz
      window.location.href = "/"; // Ana sayfa yolunu kendi yolunuzla değiştirin
    }, 2000); // 3 saniye (3000ms) sonra yönlendir
  };

  return (
    <Fragment>
      <section>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="wrapper__contact-form home">
                <h4 className="text-center normal font_size--40 text40-1024 text40-md text_40-mm yeseva mb-4">
                  Öneri, istek ve şikayetleriniz için mesaj atın. <br />
                </h4>
                {isSent && (
                  <div className="text-center mt-2">
                    <p>E-posta başarıyla gönderildi!</p>
                  </div>
                )}
                <form ref={form} onSubmit={sendEmail}>
                  {/* Form içeriği */}
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group mb-4">
                        <label htmlFor="" className="font_size--16 text_16-1024 yeseva">İsminiz</label>
                        <input
                          type="text"
                          className="form-control wrapper_field-input medium fontsize--12 text_12-1024"
                          placeholder="Kişi adı veya kurum adınızı buraya yazın."
                          name="from_name"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group mb-4">
                        <label htmlFor="" className="font_size--16 text_16-1024 yeseva">Mail Adresiniz</label>
                        <input
                          type="email"
                          className="form-control wrapper_field-input medium fontsize--12 text_12-1024"
                          placeholder="Mail adresinizi buraya girin."
                          name="user_email"
                        />
                      </div>
                    </div>

                    <div className="form-group mb-4"></div>
                  <label htmlFor="" className="font_size--16 text_16-1024 yeseva">Message</label>
                  <textarea
                  className="form-control wrapper_field-input textarea medium fontsize--12 text_12-1024"
                  placeholder="Mesajınızı buraya yazın."
                  id=""
                  cols="30"
                    rows="10"
                    name="message"
                  ></textarea>
                  </div>
                  <div className="text-center">
                  <button className="btn btn_green medium fontsize--14 text14-1024 color_gray-1">
                    Gönder
                  </button>
                  </div>
                </form>
                {/* Gönderildi mesajını göstermek için kullanılır */}
                
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Consultation;
