import React from "react";
import Post from "./Post";





const Download = () => {
    return (
        <>
            <Post />
            
            
        </>
    );
};

export default Download;
