import React from "react";
import cn from "classnames";
import styles from "./Steps.module.sass";
import ScrollParallax from "../../../components/ScrollParallax";

const items = [
  {
    title: "Anlaşma",
    color: "#3772FF",
    images: "/images/content/download.svg",
    content:
      "İsteklerinizi dinliyor ve detaylandırıyoruz",
  },
  {
    title: "Tasarım",
    color: "#9757D7",
    images: "/images/content/whistle.svg",
    content:
      "Birlikte istek ve detaylara göre bir tasarım oluşturuyoruz.",
  },
  {
    title: "Kodalama",
    color: "#EF466F",
    images: "/images/content/medal.svg",
    content:
      "En kompat ve profesiyonel bir biçimde kodluyoruz.",
  },
  {
    title: "Bitiş",
    color: "#45B26B",
    images: "/images/content/stopwatch.svg",
    content:
      "Herkes mutlu oluyor",
  },
];

const Steps = ({ scrollToRef }) => {
  return (
    <div className={cn("section", styles.section)} ref={scrollToRef}>
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          <h2 className={cn("h2", styles.title)}>Nasıl Çalışıyoruz</h2>
          <div className={styles.info}>
            Müşteri dostu, kaliteli, hızlı, ve profesiyonel
          </div>
        </div>
        <div className={styles.list}>
          {items.map((x, index) => (
            <ScrollParallax className={styles.item} key={index}>
              <div
                className={styles.preview}
                style={{ backgroundColor: x.color }}
              >
                <img src={x.images} alt={`Adım ${index}`} />
              </div>
              <div className={styles.number}>Adım {index + 1}</div>
              <div className={styles.subtitle}>{x.title}</div>
              <div className={styles.content}>{x.content}</div>
            </ScrollParallax>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Steps;
