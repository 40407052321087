import React from "react";
import cn from "classnames";
import styles from "./Platform.module.sass";

import ScrollParallax from "../../../components/ScrollParallax";

const items = [
  {
    title: "Mobil Uygulama",
    description: "Size özel sıfırdan uygulama.",
    color: "$neutrals3",
    image: "/images/content/apple.svg",
    status: "black",
    
  },
  {
    title: "Mobil Tasarım",
    description: "Size özel sıfırdan veya isteğinize göre düzenlenmiş tasarımlar.",
    color: "#$neutrals3",
    image: "/images/content/apple.svg",
    status: "green",
    
  },
  {
    title: "Web",
    description: "Size özel sıfırdan web sitesi .",
    color: "#$neutrals3",
    image: "/images/content/mouse.svg",
    status: "green",
    
  },
  {
    title: "Web Tasarım",
    description: "Size özel sıfırdan veya ",
    color: "$neutrals3",
    image: "/images/content/mouse.svg",
    status: "green",
    
  },
  {
    title: "Logo",
    description: "İyi analiz ile işiniz ve amacınız ile uyumlu logolar tasarlamak bizim işimiz.",
    color: "$neutrals3",
    image: "/images/content/android.svg",
  },
];

const Platform = () => {
  return (
    <div className={cn("section", styles.section)}>
      <div className={cn("container", styles.container)}>
        <h2 className={cn("h2", styles.title)}>Hizmetlerimiz</h2>
        <div className={styles.list}>
          {items.map((x, index) => (
            <ScrollParallax className={styles.item} key={index}>
              <div
                className={styles.preview}
                style={{ backgroundColor: x.color }}
              >
                <img src={x.image} alt="Logo" />
              </div>
              <div className={styles.details}>
                {x.status && (
                  // <div className={cn(x.status, styles.status)}>{x.statusContent}</div>
                  <div
                    className={cn(
                      { "status-stroke-black": x.status === "black" },
                      { "status-green": x.status === "green" },
                      styles.status
                    )}
                  >
                    {x.statusContent}
                  </div>
                )}
                <div className={styles.subtitle}>{x.title}</div>
                <div className={styles.description}>{x.description}</div>
                <a
                  href="/pricing"
                  className={cn("button-small", styles.button)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>İletişim</span>
                  
                </a>
              </div>
            </ScrollParallax>
          ))}
        </div>
       
      </div>
    </div>
  );
};

export default Platform;
