import React from "react";
import cn from "classnames";
import Slider from "react-slick";
import styles from "./History.module.sass";

const items = [
    {
        url: "/article/1",
        title: "Mobil uygulama ve Web tasarım, Front-end",
        content:
          "Uzman ekibimiz, sektördeki en son trendleri takip eder ve modern tasarım prensipleriyle projeleri hayata geçirir. Kullanıcı dostu arayüzler oluşturur, responsive tasarımlarla farklı cihazlara uyum sağlar ve etkileyici kullanıcı deneyimleri sunarız.",
        image: "url('/images/content/Desktop.png')",
        status: "pink",
        statusContent: "yeni",
    },
    {
        url: "/article/1",
        title: "Mobil ve Web Back-end",
        content:
            "Biz, mobil ve web uygulamaları için back-end hizmetleri sunan bir şirketiz. Müşterilerimize güçlü, güvenilir ve ölçeklenebilir back-end çözümleri sağlıyoruz.Amacımız, müşterilerimizin kullanıcı dostu ve yüksek performanslı uygulamalar geliştirmelerine yardımcı olmaktır. Mobil ve web projelerindeki veri yönetimi, sunucu tarafı programlama, API entegrasyonu gibi back-end işlemlerinde uzmanız.",

            
        image: "url('/images/content/history-pic.png')",
        status: "green",
        statusContent: "",
    },
    {
        url: "/article/1",
        title: "Yazılım Danışmanlığı",
        content:
            "Uzman ekibimiz, sektördeki en son trendleri takip eder ve müşterilerimize en uygun teknolojileri önerir. İhtiyaçları analiz eder, gereksinimleri belirler ve projelerin başarılı bir şekilde hayata geçirilmesi için stratejiler geliştiririz.",
        image: "url('/images/content/history-pic.png')",
        status: "pink",
        statusContent: "new",
    },
    {
        url: "/article",
        title: "Destek ve Bakım",
        content:
            "Biz, yazılım destek ve bakım hizmetleri sunan bir firmayız. Müşterilerimize sorun giderme, hata düzeltme, teknik danışmanlık ve kişisel destek gibi konularda yardımcı oluyoruz. Ayrıca, yazılım sistemlerinin güncel ve güvenli kalmasını sağlamak için bakım faaliyetleri gerçekleştiriyoruz.",
        image: "url('/images/content/history-pic.png')",
        status: "green",
        statusContent: "yoga",
    },
];

const History = () => {
    const settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: false,
        adaptiveHeight: true,
    };

    return (
        <div className={styles.history}>
            <div className={styles.wrap}>
                <Slider className="history-slider" {...settings}>
                    {items.map((x, index) => (
                        <div className={styles.slide} key={index}>
                            <div className={cn("history-item", styles.item)}>
                                <div
                                    className={styles.preview}
                                    style={{ backgroundImage: x.image }}
                                ></div>
                                <div className={styles.details}>
                                    <div
                                        className={cn(
                                            {
                                                "status-pink":
                                                    x.status === "pink",
                                            },
                                            {
                                                "status-green":
                                                    x.status === "green",
                                            },
                                            styles.status
                                        )}
                                    >
                                        
                                    </div>
                                    <div className={styles.title}>
                                        {x.title}
                                    </div>
                                    <div className={styles.content}>
                                        {x.content}
                                    </div>
                                    
                                </div>
                                <div className={styles.position}>
                                    {x.position}
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
};

export default History;
