
import cn from "classnames";
import styles from "./Team.module.sass";




// data
import { trainersList } from "../../../mocks/trainers";


const Team = () => {
  const options = [];
  trainersList.map((x) => options.push(x.title));

  

  
  

  return (
    <div className={styles.section}>
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          <h2 className={cn("h2", styles.title)}>Ekipimiz</h2>
          <div className={styles.info}>
            Şirketimizde çalışanlarımız, mükemmeliyeti yakalamak için büyük bir özveri ve tutkuyla çalışan değerli bireylerdir. Onların bilgi birikimi, yetenekleri ve tecrübeleri, dijital çözümlerimizin kalitesini ve başarısını belirleyen temel unsurlardır..
          </div>
          
          
        </div>
      </div>
    </div>
  );
};

export default Team;
