import React from "react";
import cn from "classnames";
import styles from "./Post.module.sass";

import { useParams } from "react-router";
import { useEffect, useState } from "react";

const Post = (props) => {
  const [newData, setNewData] = useState();

  const data = [
    { name: "mobil", id: "2" },
    { name: "mobil3", id: "3" },
    { name: "mobil4", id: "4" },
    { name: "mobil5", id: "5" },
    { name: "mobil6", id: "6" },
    { name: "mobil4", id: "4" }
  ];

  const userId = useParams()[0];
  console.log(userId);

  const matchedItem = data.find((item) => item.id === userId);
  console.log(matchedItem);

  useEffect(() => {
    fetch(`http://localhost:3000/api/data/${Number(userId)}`)
      .then((response) => response.json())
      .then((json) => setNewData(json));
  }, [userId]);

 console.log("newData",  newData);

 return (
  <div className={cn("section", styles.section)}>
    <div className={cn("container", styles.container)}>
      <div className={styles.head}>
        <h1 className={cn("h1", styles.description)}>
          {newData?.heading}
        </h1>
      </div>
      
        <div className={styles.col}>{newData?.image}</div>
        <div className={styles.col}>
          <h2 className={cn("h2", styles.title)}>{newData?.description}</h2>
          <div className={styles.content}>
            <p>{newData?.title}</p>
          </div>
          <div className={styles.info}></div>
          <div className={styles.preview}>
            {newData?.images && (
              <div className={cn(styles.imageGrid, "imageGrid")}>
                {newData.images.map((image) => (
                  <img
                    key={image.id}
                    src={`http://localhost:3000/images/${image.url}`}
                    alt="Resim"
                  />
                ))}
              </div>
            )}
          </div>
          <div className={styles.content}>
            <p>{newData?.title}</p>
            <p></p>
          </div>
        </div>
      </div>
    </div>
  
)};


export default Post;
