import React from "react";
import cn from "classnames";
import styles from "./About.module.sass";

import ScrollParallax from "../../../components/ScrollParallax";

const items = [
  {
    title: "Vizyonumuz",
    content:
      "Müşterilerimizin ihtiyaçlarına odaklanarak, kaliteli, müşteri dostu ve hızlı dijital çözümler sunan yenilikçi bir şirket olmak..",
    color: "#9757D7",
  },
  {
    title: "Misyonumuz",
    content:
      "Müşterilerimize en iyi hizmeti sunmak için kalitemizden ödün vermeden, onların ihtiyaçlarını anlayarak hızlı ve yenilikçi dijital çözümler geliştirmek. Müşterilerimizin işlerini büyütmek, dijital dünyada rekabet avantajı sağlamak ve memnuniyetlerini her zaman en üst düzeyde tutmak için sürekli kendimizi geliştirmek ve yenilikçi yaklaşımlarla öncü olmak.",
    color: "#EF466F",
  },
  {
    title: "Çalışanlarımız",
    content:
      "Çalışanlarımız, şirketimizin en değerli varlıklarıdır ve başarılarımızın temelini oluştururlar. Onlar, vizyonumuza ulaşmak için güçlü bir ekip ruhuyla bir araya gelen, yetenekli ve tutkulu profesyonellerdir. İşbirliği, yaratıcılık ve özveri ile çalışanlarımız, müşteri odaklı yaklaşımımızı destekleyerek kaliteli hizmetlerimizi sunmaktadır.",
    color: "#45B26B",
  },
];

const About = () => {
  return (
    <div className={cn("section", styles.book)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={cn("stage", styles.stage)}>Vizyonumuz ve misyonumuz</div>
          </div>
          <div className={styles.col}>
            <h2 className={cn("h2", styles.title)}>
              
            </h2>
            <div className={styles.info}>
              Bikodla ailesi olarak işimizi müşteri dostu, güvenilir, kendine özgü ve profesiyonel yapıyoruz
            </div>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={styles.list}>
              {items.map((x, index) => (
                <ScrollParallax className={styles.item} key={index}>
                  <div
                    className={styles.number}
                    style={{ backgroundColor: x.color }}
                  >
                    0{index + 1}
                  </div>
                  <div className={styles.subtitle}>{x.title}</div>
                  <div className={styles.content}>{x.content}</div>
                </ScrollParallax>
              ))}
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.bg}>
              <img
                srcSet="/images/content/about-pic-1@2x.png 2x"
                src="/images/content/about-pic-1.png"
                alt="About pic"
              />
              <div>
                
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
