import React from "react";


const Advantages = () => {
  return (
    <div>
 
    </div>
  );
};

export default Advantages;
