import React from "react";
import Hero from "./Hero";
import Platform from "./Platform";
import Community from "../../components/Community";
import About from "../Features/About";

const Download = () => {
    return (
        <>
            <Hero />
            <Platform />
            <About />
            
            <Community />
        </>
    );
};

export default Download;
