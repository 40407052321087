import React from "react";
import cn from "classnames";
import Slider from "react-slick";
import Icon from "../Icon";
import styles from "./Review.module.sass";

const items = [
  {
    author: "",
    position: "",
    logo: "./images/content/figma_logo.png",
    comment:
      "Çalışanlarımızın Figma yetkinliği, şirketimizin vizyonunu gerçekleştirmemizde büyük bir rol oynar. Tasarımlarının mükemmelliği ve özgünlüğü, markalarımızın dijital varlıklarını benzersiz kılar ve rekabet avantajı sağlar.",
  },
  {
    author: "",
    position: "",
    logo: "./images/content/react-icon.png",
    comment:
      "Çalışanımız, proje ekipleriyle uyumlu bir şekilde çalışarak, tasarım ve geliştirme süreçlerini sinerji içinde yönetir. Kendi alanında uzman biri olarak, projelerin gereksinimlerini anlamak ve kullanıcı odaklı bir yaklaşımla tasarımları hayata geçirmek için işbirliği içinde çalışır..",
  },
  {
    author: "",
    position: "",
    logo: "./images/content/google-flutter-logo.png",
    comment:
      "Flutter kullanan çalışanlarımızın vizyonu, yenilikçi ve etkileyici mobil uygulamalar geliştirerek dijital dünyada fark yaratmaktır. Bu vizyon doğrultusunda, kullanıcı dostu arayüzler ve akıcı kullanıcı deneyimleri sunarak, müşterilerimize değer katmayı hedeflemektedirler.",
  },
];

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props}>{children}</button>
);

const Review = ({ className }) => {
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    nextArrow: (
      <SlickArrow>
        <Icon name="arrow-next" size="14" />
      </SlickArrow>
    ),
    prevArrow: (
      <SlickArrow>
        <Icon name="arrow-prev" size="14" />
      </SlickArrow>
    ),
  };

  return (
    <div className={cn(className, styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.wrap}>
          <Slider className="review-slider" {...settings}>
            {items.map((x, index) => (
              <div className={styles.slide} key={index}>
                <div className={cn("review-item", styles.item)}>
                  <div className={styles.logo}>
                    <img src={x.logo} alt="Logo" />
                  </div>
                  <div className={styles.title}>{x.comment}</div>
                  <div className={styles.author}>{x.author}</div>
                  <div className={styles.position}>{x.position}</div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Review;
