import React from "react";
import cn from "classnames";
import styles from "./Community.module.sass";
import Subscription from "../Subscription";
import Image from "../Image";

const Community = () => {
  return (
    <div className={cn("section-pb", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.preview}>
          <Image
            srcSet="/images/content/dumbbell@2x.png 2x"
            srcSetDark="/images/content/dumbbell-dark@2x.png 2x"
            src="/images/content/dumbbell.png"
            srcDark="/images/content/dumbbell-dark.png"
            alt="Dumbbell"
          />
        </div>
        <div className={cn("stage-small", styles.stage)}>
          Bilgi almak için
        </div>
        <h2 className={cn("h2", styles.title)}>Bizimle çalışmak ister misiniz ?</h2>
        <div className={styles.text}>
          Talepleriniz ve bilgi almak için lütfen iletişime geçin.
        </div>
        <Subscription
          className={styles.subscription}
          placeholder="Mail adresinizi girin"
        />
        
      </div>
    </div>
  );
};

export default Community;
