import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Hero.module.sass";
import History from "./History";
import Item from "./Item";
import ScrollParallax from "../../../components/ScrollParallax";

const Hero = () => {
  const [items, setItems] = useState([]);

  const fetchUserData = () => {
    fetch("http://localhost:3000/api/item")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setItems(data);
      });
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  if (items.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div className={cn("section", styles.section)}>
      <div className={cn("container", styles.container)}>
        <h2 className={cn("hero", styles.title)}>Portfolyo</h2>
        <div className={styles.info}>
          İşletmenizi Sanal Dünyaya Taşıyın, Biz de Size Yol Gösterelim!.
        </div>
        <History />
        <div className={styles.nav}></div>
        <div className={styles.list}>
          {items.map((x, index) => (
            <ScrollParallax className={styles.box} key={index}>
              <Item item={x} className={styles.item} />
            </ScrollParallax>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Hero;
